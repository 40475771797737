import { UserAuthRol } from "./user-auth-rol.model";
import { UserAuthSistema } from "./user-auth-sistema.model";

export class UserAuth {
	id_usuario: number;
	usuario: string;
	nombre_persona: string;
	primer_apellido: string;
	segundo_apellido: string;
	numero_documento: string;
	genero: string;
	roles: UserAuthRol[];
	sistemas: UserAuthSistema[];

}
