<div class="flex items-center" mat-dialog-title>
  <h3 class="headline m-0 flex-auto">{{data.title}}</h3>
</div>
<mat-divider class="text-border"></mat-divider>
<mat-dialog-content class="flex flex-col">
  <p [innerHTML]="message"></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">{{appSettings.CONFIRM_DIALOG_NEGATIVE}}</button>
  <button mat-flat-button (click)="onConfirm()" color="primary"
    type="submit">{{appSettings.CONFIRM_DIALOG_POSITIVE}}</button>
</mat-dialog-actions>