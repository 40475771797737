export class AppRooute {
    title: string;
    url: string;
    urlBreadcrumb: string;

    constructor(titie: string, url: string) {
        this.title = titie;
        this.url = url;
        this.urlBreadcrumb = "/" + url;
    }
}