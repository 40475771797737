import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Settings } from 'luxon';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ConfigService } from '../@vex/config/config.service';
import { LayoutService } from '../@vex/services/layout.service';
import { NavigationService } from '../@vex/services/navigation.service';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { RouteConstant } from './core/common/route-constant';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    this.configService.updateConfig({
      sidenav: {
        title: 'DSC - GAMLP',
        imageUrl: 'assets/img/dsc/logo_gamlp.png',
        showCollapsePin: true
      },
      footer: {
        visible: false
      }
    });

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Tablero de mando',
            route: '/dashboard',
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Casos',
        children: [
          {
            type: 'link',
            label: RouteConstant.ROUTE_CASE_MISSING.title,
            route: RouteConstant.ROUTE_CASE_MISSING.url,
            icon: 'mat:assignment_ind',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: RouteConstant.ROUTE_REPORT_MISSING.title,
            route: RouteConstant.ROUTE_REPORT_MISSING.url,
            icon: 'mat:assignment',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Notificaciones',
        children: [
          {
            type: 'link',
            label: RouteConstant.ROUTE_CASE_SOCIO.title,
            route: RouteConstant.ROUTE_CASE_SOCIO.url,
            icon: 'mat:supervisor_account',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: RouteConstant.ROUTE_CASE_GRUPO.title,
            route: RouteConstant.ROUTE_CASE_GRUPO.url,
            icon: 'mat:group_work',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: RouteConstant.ROUTE_CASE_NOTIFICACION.title,
            route: RouteConstant.ROUTE_CASE_NOTIFICACION.url,
            icon: 'mat:contact_mail',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Alerta Gris',
        children: [
          {
            type: 'link',
            label: RouteConstant.ROUTE_MONITOR.title,
            route: RouteConstant.ROUTE_MONITOR.url,
            icon: 'mat:contact_mail',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: RouteConstant.ROUTE_PUBLISHED.title,
            route: RouteConstant.ROUTE_PUBLISHED.url,
            icon: 'mat:assignment_ind',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: RouteConstant.ROUTE_CONFIG.title,
            route: RouteConstant.ROUTE_CONFIG.url,
            icon: 'mat:settings',
            routerLinkActiveOptions: { exact: true }
          }
          
        ]
      }
    ];
  }
}
