import { AppRooute } from "./app-route.model";

export class RouteConstant {
    static readonly MODULE_CASE = "casos";
    static readonly ROUTE_CASE_MISSING = new AppRooute('Desaparecidos', this.MODULE_CASE + "/desaparecidos");
    static readonly ROUTE_REPORT_MISSING = new AppRooute('Busqueda y Reportes', this.MODULE_CASE + "/reportes");
    static readonly ROUTE_CASE_SOCIO = new AppRooute('Socios', this.MODULE_CASE + "/socios");
    static readonly ROUTE_CASE_GRUPO = new AppRooute('Grupos', this.MODULE_CASE + "/grupos");
    static readonly ROUTE_CASE_NOTIFICACION = new AppRooute('Notificaciones', this.MODULE_CASE + "/notificaciones");

    static readonly MODULE_AUTH = "auth"
    static readonly ROUTE_LOGIN = new AppRooute('Login', this.MODULE_AUTH + "/login");

    // segunda consultoria
    static readonly ROUTE_MONITOR = new AppRooute('Monitores', this.MODULE_CASE + "/monitores");
    static readonly ROUTE_DISPLAY = new AppRooute('Display', this.MODULE_CASE + "/display");
    static readonly ROUTE_PUBLISHED = new AppRooute('Publicados', this.MODULE_CASE + "/publicados");
    static readonly ROUTE_CONFIG = new AppRooute('Configuración', this.MODULE_CASE + "/configuracion");
    //static readonly ROUTE_CASE_GRUPO = new AppRooute('Grupos', this.MODULE_CASE + "/grupos");

}

