<div class="w-full p-gutter text-center">
  <img class="w-full max-w-lg mx-auto mb-6" src="assets/img/illustrations/data_center.svg">

  <h1 class="display-3 font-medium m-0">404 That’s an error.</h1>
  <h2 class="headline m-0">The requested URL <b>{{path}}</b> was not found on this server. </h2>

  <p class="text-secondary text-center mt-10">
    Already have an account?<br />
    <b><a [routerLink]="['/login']">Sign in here</a></b>
  </p>
</div>