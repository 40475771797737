import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserAuthDto } from 'src/app/shared/models/auth/user-auth-dto.model';
import { UserAuth } from 'src/app/shared/models/auth/user-auth.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _user: UserAuth;
    private _token: string;

    constructor(private http: HttpClient) {
        console.log(environment.apiSecurityUrl);
    }

    public get user(): UserAuth {
        if (this._user != null) {
            return this._user;
        } else if (this._user == null && localStorage.getItem('user') != null) {
            this._user = JSON.parse(localStorage.getItem('user')) as UserAuth;
            return this._user;
        }
        return new UserAuth();
    }

    public get token(): string {
        if (this._token != null) {
            return this._token;
        } else if (this._token == null && localStorage.getItem('token') != null) {
            this._token = localStorage.getItem('token');
            return this._token;
        }
        return null;
    }

    login(user: UserAuthDto): Observable<any> {
        return this.http.post<any>(environment.apiSecurityUrl + 'auth/login', user);
    }

    saveUser(userAuth: UserAuth): void {
        localStorage.setItem('user', JSON.stringify(userAuth));
    }
    saveToken(accessToken: string): void {
        this._token = accessToken;
        localStorage.setItem('token', accessToken);
    }

    getDataToken(accessToken: string): any {
        if (accessToken != null) {
            return JSON.parse(atob(accessToken.split(".")[1]));
        }
        return null;
    }

    isAuthenticated(): boolean {
        let payload = this.getDataToken(this.token);

        if (payload != null && payload.user_name && payload.user_name.length > 0) {
            return true;
        }
        return false;
    }

    isExpired(): boolean {
        if (localStorage.getItem('token') != null) {
            const expiry = (JSON.parse(atob(this.token.split('.')[1]))).exp;
            return (Math.floor((new Date).getTime() / 1000)) >= expiry;
        } else {
            return true;
        }
    }

    hasRole(role: string): boolean {
        if (this.user.roles[0].nombre_rol.includes(role)) {
            return true;
        }
        return false;
    }

    logout(): void {
        this._user = null;
        this._token = null;
        localStorage.clear();
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }
}