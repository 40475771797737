export class AppSettings {
    static readonly PAGE_SIZE = 10;
    static readonly PAGE_SIZE_OPTIONS = [5, 10, 20, 50];
    static readonly CONFIRM_DIALOG_POSITIVE = "SI";
    static readonly CONFIRM_DIALOG_NEGATIVE = "NO";
    static readonly DIALOG_BUTTON_PERSIST_POSITIVE = "Registrar";
    static readonly DIALOG_BUTTON_AGGREGATE_POSITIVE = "Agregar";
    static readonly DIALOG_BUTTON_UPDATE_POSITIVE = "Actualizar";
    static readonly DIALOG_BUTTON_CANCEL = "Cancelar";
    static readonly DIALOG_BUTTON_CLOSE = "Cerrar";
    static readonly DIALOG_WIDTH = "400px";

    static readonly BUTTON_EDIT = "Editar";
    static readonly BUTTON_DELETE = "Eliminar";
};